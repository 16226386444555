"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
var colors = {
    black: "#000000",
    blue: "#1B75DF",
    red: "#fa5252",
    white: "#ffffff",
    errorRed: "#D42A54",
    errorPlaceholder: "#ff8787",
    buttonBlack: "#2C2C2C",
    dividerGrey: "#C8C9CA",
    background: "#F3F2F3",
    boxBorder: "#CCCCCC",
    borderColor: "#B4B3BA",
    theme: "#827127",
    iconTeal: "#0C8599",
    dialogFooter: "#F7F7F7",
    shadow: "#2525251A",
    borderSection: "#b4b4b4"
};
var _default = colors;
