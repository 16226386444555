import { AccountProvider } from '@collinsonx/constants/enums';

import { FAQ_LK, FAQ_PP } from '../config/Constants';

export const verifyAccountProvider = (provider: AccountProvider) => {
  if (provider === AccountProvider.PP) {
    return FAQ_PP;
  }
  if (provider === AccountProvider.LK) {
    return FAQ_LK;
  }
  return FAQ_PP;
};
