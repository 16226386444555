import { AccountProvider, Client } from '@collinsonx/constants/enums';
import {
  hsbc,
  loungeKey,
  mastercard,
  priorityPass,
} from '@collinsonx/design-system';
import { BridgePayload } from 'types/booking';

const { LK, PP } = AccountProvider;
const { Mastercard_HSBC, Mastercard_MCAE } = Client;

export const mantineTheme = (payload?: BridgePayload) => {
  const { accountProvider, membershipType } = payload || {};

  type MembershipTypeThemes =
    | Client.Mastercard_HSBC
    | Client.Mastercard_MCAE
    | undefined;

  const membershipTypeBasedThemes: MembershipTypeThemes[] = [
    Mastercard_HSBC,
    Mastercard_MCAE,
  ];
  const membershipTypeTheme = membershipTypeBasedThemes.find(
    (theme) => theme === membershipType
  );

  const selectedTheme = membershipTypeTheme ?? accountProvider ?? PP;

  const AVAILABLE_THEMES = {
    [LK]: loungeKey,
    [Mastercard_HSBC]: hsbc,
    [Mastercard_MCAE]: mastercard,
    [PP]: priorityPass,
  };

  return AVAILABLE_THEMES[selectedTheme]?.();
};
