type Observer<T> = (data: T) => void;

export interface IObservable<T> {
  notify: (data: T) => void;
  subscribe: (observer: Observer<T>) => () => void;
}

export default class Observable<T> {
  private observers: Observer<T>[];

  constructor() {
    this.observers = [];
  }

  notify(data: T): void {
    this.observers.forEach((observer) => observer(data));
  }

  subscribe(observer: Observer<T>): () => void {
    this.observers.push(observer);

    return () => this.unsubscribe(observer);
  }

  private unsubscribe(observer: Observer<T>): void {
    this.observers = this.observers.filter((obs) => obs !== observer);
  }
}
