"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/apolloBooking.ts
var apolloBooking_exports = {};
__export(apolloBooking_exports, {
  APOLLO_STATE_PROP_NAME: () => APOLLO_STATE_PROP_NAME,
  ApolloError: () => import_client2.ApolloError,
  ApolloProvider: () => import_client2.ApolloProvider,
  addApolloState: () => addApolloState,
  gql: () => import_client2.gql,
  initializeApollo: () => initializeApollo,
  useApollo: () => useApollo,
  useLazyQuery: () => import_client2.useLazyQuery,
  useMutation: () => import_client2.useMutation,
  useQuery: () => import_client2.useQuery
});
module.exports = __toCommonJS(apolloBooking_exports);
var import_client = require("@apollo/client");
var import_link_error = require("@apollo/link-error");
var import_session = require("supertokens-auth-react/recipe/session");
var import_deepmerge = __toESM(require("deepmerge"));
var import_isEqual = __toESM(require("lodash/isEqual"));
var import_react = require("react");
var import_context = require("@apollo/client/link/context");

// src/lib/cookieHandler.ts
var frontendCookiesKey = "frontendCookies";
var inMemoryStorage = {};
function setKeyValue(key, value) {
  try {
    if (window) {
      window.localStorage.setItem(key, value);
    }
  } catch (err) {
    inMemoryStorage[key] = value;
  }
}
function getKeyValue(key) {
  try {
    return window ? window.localStorage.getItem(key) : null;
  } catch (err) {
    if (inMemoryStorage[key] === void 0) {
      return null;
    } else {
      return inMemoryStorage[key];
    }
  }
}
function getCookiesFromStorage() {
  const cookiesFromStorage = getKeyValue(frontendCookiesKey);
  if (cookiesFromStorage === null) {
    setKeyValue(frontendCookiesKey, "[]");
    return "";
  }
  const cookieArrayInStorage = JSON.parse(cookiesFromStorage);
  let cookieArrayToReturn = [];
  for (let cookieIndex = 0; cookieIndex < cookieArrayInStorage.length; cookieIndex++) {
    const currentCookieString = cookieArrayInStorage[cookieIndex];
    const parts = currentCookieString.split(";");
    let expirationString = "";
    for (let partIndex = 0; partIndex < parts.length; partIndex++) {
      const currentPart = parts[partIndex];
      if (currentPart.toLocaleLowerCase().includes("expires=")) {
        expirationString = currentPart;
        break;
      }
    }
    if (expirationString !== "") {
      const expirationValueString = expirationString.split("=")[1];
      const expirationDate = new Date(expirationValueString);
      const currentTimeInMillis = Date.now();
      if (expirationDate.getTime() < currentTimeInMillis) {
        continue;
      }
    }
    cookieArrayToReturn.push(currentCookieString);
  }
  setKeyValue(frontendCookiesKey, JSON.stringify(cookieArrayToReturn));
  return cookieArrayToReturn.join("; ");
}

// src/lib/index.ts
var cookieStringToObject = (cookieString) => {
  const str = cookieString.split("; ");
  const result = {};
  for (let i in str) {
    const cur = str[i].split("=");
    result[cur[0]] = cur[1].replace(";expires", "");
  }
  return result;
};

// src/apolloBooking.ts
var import_client2 = require("@apollo/client");
var graphqlUrl = process.env.NEXT_PUBLIC_PRODUCTION_API_URL;
var defaultOptions = {
  watchQuery: {
    fetchPolicy: "network-only",
    errorPolicy: "all"
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "all"
  },
  mutate: {
    fetchPolicy: "network-only",
    errorPolicy: "all"
  }
};
var authLink = (0, import_context.setContext)((_, { headers }) => {
  const cookieStr = getCookiesFromStorage();
  const cookies = cookieStringToObject(cookieStr);
  const token = cookies["st-access-token"] || "";
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});
var httpLink = new import_client.HttpLink({
  uri: graphqlUrl,
  credentials: "include"
});
var errorLinkHandler = ({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(
      ({ message, locations, path }) => console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }
  if (networkError) {
    console.log(
      `[Network error]: ${networkError}. Backend is unreachable. Is it running?`,
      `[Graphql URL]: ${graphqlUrl}`
    );
  }
};
var APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";
var authMiddleware = new import_client.ApolloLink((operation, forward) => {
  return (0, import_client.fromPromise)(
    (0, import_session.doesSessionExist)().then(() => {
      console.log("Pre-GQL refresh session if expired");
      return (0, import_client.toPromise)(forward(operation));
    })
  );
});
var apolloClient;
function createApolloClient(errorObservable) {
  const errorLink = (0, import_link_error.onError)((err) => {
    errorLinkHandler(err);
    if (errorObservable)
      errorObservable.notify(err);
  });
  return new import_client.ApolloClient({
    ssrMode: typeof window === "undefined",
    link: import_client.ApolloLink.from([authLink, authMiddleware, errorLink, httpLink]),
    cache: new import_client.InMemoryCache(),
    defaultOptions
  });
}
function initializeApollo(initialState = null, errorObservable) {
  const _apolloClient = apolloClient ?? createApolloClient(errorObservable);
  if (initialState) {
    const existingCache = _apolloClient.extract();
    const data = (0, import_deepmerge.default)(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(
          (d) => sourceArray.every((s) => !(0, import_isEqual.default)(d, s))
        )
      ]
    });
    _apolloClient.cache.restore(data);
  }
  if (typeof window === "undefined")
    return _apolloClient;
  if (!apolloClient)
    apolloClient = _apolloClient;
  return _apolloClient;
}
function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }
  return pageProps;
}
function useApollo(pageProps, errorObservable) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = (0, import_react.useMemo)(
    () => initializeApollo(state, errorObservable),
    [state, errorObservable]
  );
  return store;
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  APOLLO_STATE_PROP_NAME,
  ApolloError,
  ApolloProvider,
  addApolloState,
  gql,
  initializeApollo,
  useApollo,
  useLazyQuery,
  useMutation,
  useQuery
});
