import {
  Experience,
  Maybe,
  Outlet,
  PrimaryProductAccessType,
  Product,
  ProductCategory,
  ProductCostType,
  ProductStage,
  Programme,
  Status,
  Tier,
} from '@collinsonx/utils';

const partnerKey = process.env.NEXT_PUBLIC_SNAPLOGIC_PARTNER_KEY
  ? process.env.NEXT_PUBLIC_SNAPLOGIC_PARTNER_KEY
  : 'partnerIdProd';

export type Lounge = {
  airportCode: string;
  airportName: string;
  currency: string;
  id: string;
  imageUrl: string;
  isPC: boolean;
  loungeCode: string;
  loungeName: string;
  productId: string;
  saleprice?: number;
  stripeId?: string;
  terminal: string;
  timezone?: string;
};

export const convertOutletToLounge = (
  OutletIn: Outlet,
  product: Maybe<Product>
): Lounge => ({
  airportCode: OutletIn?.location.code ?? '',
  airportName: OutletIn.location.name ?? '',
  currency: OutletIn?.products[0]?.salePrices[0]?.salePriceCurrency ?? '',
  id: OutletIn?.id,
  imageUrl: OutletIn.media?.mainImage?.asset?.url ?? '',
  isPC: true,
  loungeCode: OutletIn.legacyCode ?? '',
  loungeName: OutletIn.name ?? '',
  productId:
    OutletIn.partnerBrand.partnerIntegrationData?.partnerIntegrationID ?? '',
  saleprice: OutletIn?.products.filter(
    (productItem) =>
      productItem?.accessType === PrimaryProductAccessType.ReservationFeeOnly
  )[0]?.salePrices[0]?.salePrice,
  stripeId: product?.id ?? '',
  terminal: OutletIn.location.terminal ?? '',
  timezone: OutletIn.location.timezone ?? '',
});

export const convertExperienceToLounge = (LoungeIn: Experience) => ({
  airportCode: LoungeIn.location?.airportCode ?? '',
  airportName: LoungeIn.location?.airportName ?? '',
  currency: LoungeIn?.pricing?.currency ?? '',
  id: LoungeIn.id,
  imageUrl: LoungeIn.images ? LoungeIn.images[0]?.url ?? '' : '',
  isPC: false,
  loungeCode: LoungeIn.loungeCode ?? '',
  loungeName: LoungeIn.loungeName ?? '',
  productId: LoungeIn.partnerIntegrationId ?? '',
  saleprice: LoungeIn?.pricing?.reservationOnlyFee ?? undefined,
  stripeId: LoungeIn.id,
  terminal: LoungeIn.location?.terminal ?? '',
  timezone: LoungeIn?.location?.timezone ?? '',
});

export const convertLoungeToProduct = (
  LoungeIn: Experience
): Maybe<Product> => ({
  accessType: PrimaryProductAccessType.Reservation,
  category: ProductCategory.Lounge,
  costs: [
    {
      __typename: 'ProductCost',
      cost: 16.52,
      costCurrency: 'GBP',
      defaultTaxPercentage: 20,
      programme: Programme.PriorityPass,
      projectedCost: 0,
      reservationCost: 0,
      type: ProductCostType.Flat,
    },
  ],
  id: '',
  name: '',
  outlet: null,
  partnerIntegrationData: {
    __typename: 'ProductPartnerIntegrationData',
    partnerProductID:
      partnerKey === 'partnerIdTest'
        ? LoungeIn.partnerIdTest
        : LoungeIn.partnerIdProd,
  },
  /** The Stripe ID of the product */
  ppStripeID: '',
  salePrices: [
    {
      __typename: 'ProductSalePrice',
      programme: Programme.PriorityPass,
      salePrice: 24,
      salePriceCurrency: 'GBP',
      stripePriceID: null,
    },
  ],
  salesforceID: '',
  stage: ProductStage.Live,
  status: Status.Active,
  tags: null,
  tier: Tier.Black,
});
