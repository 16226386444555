import {
  loggerAction,
  loggerDataError,
  loggerInfo,
} from '@collinsonx/utils/lib/analytics';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { DATADOG_ERROR_LOGS, DATADOG_INFO_LOGS } from '../constants';

type DatadogErrorKey = keyof typeof DATADOG_ERROR_LOGS;
type DatadogInfoKey = keyof typeof DATADOG_INFO_LOGS;

type LogOptions = {
  customError?: Error;
  data?: unknown;
};

const logDataError = (error: DatadogErrorKey, options: LogOptions = {}) => {
  const { customError, data } = options;

  const hide = process.env.NEXT_PUBLIC_DATADOG_INFOLOGS_SWITCH
    ? process.env.NEXT_PUBLIC_DATADOG_INFOLOGS_SWITCH.length > 0
    : false;

  const dataDogError = DATADOG_ERROR_LOGS[error];
  const errorObject = customError || new Error(dataDogError.title);

  loggerDataError(
    errorObject,
    'dataLogs',
    dataDogError.action,
    data,
    hide,
    datadogLogs
  );
};

const logInfo = (info: DatadogInfoKey, options: LogOptions = {}) => {
  const { data } = options;

  const hide = process.env.NEXT_PUBLIC_DATADOG_INFOLOGS_SWITCH
    ? process.env.NEXT_PUBLIC_DATADOG_INFOLOGS_SWITCH.length > 0
    : false;

  const dataDogInfo = DATADOG_INFO_LOGS[info];

  loggerInfo('dataLogs', dataDogInfo, data, hide, datadogLogs);
};

const logAction = async (file: string, action: string, data?: unknown) => {
  await loggerAction(file, action, data, datadogRum);
};

export { logAction, logDataError, logInfo };
