import { Slots } from '@collinsonx/utils';
import React, {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';

export interface Booking {
  adults: number;
  amendmentCurrentAttendees: number;
  amendmentID?: string;
  arrival?: string;
  arrivalTime?: string;
  bookingId?: string;
  carrierCode?: string;
  children: number;
  currentPrice: number | undefined;
  departureDate?: Date | null;
  existing_booking_slot: string;
  flightNumber: string;
  guestsCount: number;
  infants: number;
  pendingPayment: boolean;
  priceIsAmended: boolean;
  quantity: number;
  reference: string | undefined;
  selectedEntitlementsCount: number;
  selectedSlot?: Slots;
  slotConfigurationId?: string;
}

interface BookingContextProps {
  booking: Booking;
  setBooking: (booking: {}) => void;
}

const setBookingStorage = (booking: Booking) => {
  if (typeof window === 'undefined') return;

  sessionStorage.setItem('bookingdetail', JSON.stringify(booking));
};

const getBookingStorage = () => {
  if (typeof window === 'undefined') return {};

  const storageObject = JSON.parse(
    sessionStorage.getItem('bookingdetail') ?? '{}'
  );

  return {
    ...defaultPropertyValues,
    ...storageObject,
  };
};

const defaultPropertyValues = {
  guestsCount: 0,
  pendingPayment: false,
  priceIsAmended: false,
  quantity: 0,
  selectedEntitlementsCount: 0,
};

const defaultContext = {
  booking: getBookingStorage(),
  setBooking: () => {},
};

export const BookingContext =
  createContext<BookingContextProps>(defaultContext);

const BookingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const setBookingInterceptor = (booking: Booking): Booking => {
    const { adults, children, departureDate, selectedEntitlementsCount } =
      booking;

    const guestsCount = children + adults;
    const pendingPayment = guestsCount > selectedEntitlementsCount;
    const quantity = guestsCount - selectedEntitlementsCount;

    return {
      ...booking,
      guestsCount,
      pendingPayment,
      quantity,
      selectedEntitlementsCount: departureDate ? selectedEntitlementsCount : 0,
    };
  };

  const [bookingState, setBookingState] = useState<Booking>(
    setBookingInterceptor(getBookingStorage())
  );

  const setBooking = (value: {}) => {
    setBookingState((oldBookingState) =>
      setBookingInterceptor({
        ...oldBookingState,
        ...value,
      })
    );
  };

  useEffect(() => {
    setBookingStorage(bookingState);
  }, [bookingState]);

  const contextValue = useMemo(
    () => ({
      booking: bookingState,
      setBooking,
    }),
    [bookingState]
  );

  return (
    <BookingContext.Provider value={contextValue}>
      {children}
    </BookingContext.Provider>
  );
};

export default BookingProvider;
