import {
  AccountProvider,
  BookingQueryParams,
} from '@collinsonx/constants/enums';
import LoaderLifestyleX from '@collinsonx/design-system/components/loaderLifestyleX';
import { Flex, MantineProvider } from '@collinsonx/design-system/core';
import { resolver } from '@collinsonx/design-system/themes/baseTheme';
import { decodeJWT } from '@collinsonx/jwt';
import { LinkedAccount } from '@collinsonx/utils';
import { useLazyQuery, useMutation } from '@collinsonx/utils/apollo';
import { ErrorResponse } from '@collinsonx/utils/apolloBooking';
import updateConsumer from '@collinsonx/utils/mutations/updateConsumer';
import { getConsumerByID } from '@collinsonx/utils/queries';
import LoungeError from '@components/LoungeError';
import { getItem, setItem } from '@lib';
import { mantineTheme } from '@lib/theme';
import {
  createInstance,
  OptimizelyProvider,
  ReactSDKClient,
} from '@optimizely/react-sdk';
import useLocale from 'hooks/useLocale';
import Head from 'next/head';
import { useRouter } from 'next/router';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  signOut,
  useSessionContext,
} from 'supertokens-auth-react/recipe/session';
import { BridgePayload } from 'types/booking';
import { Consumer } from 'types/consumer';
import { IObservable } from 'utils/observer';

import {
  ALLOW_LOCAL,
  apiAccountProviderMap,
  BookingError,
  JWT,
  LANGUAGE,
  LOUNGE_CODE,
  PLATFORM,
  REFERRER,
  VERSION,
} from '../constants';
import {
  accountIsEqual,
  consumerIsValid,
  hasRequired,
  log,
  logDataError,
} from '../lib/index';
import { ErrorHook } from './error';
import { OutletHook } from './outlet';

const { LK } = AccountProvider;

const {
  jwt: jwtParam,
  ln,
  loungeCode: lcParam,
  platform: platformParam,
  referrer: referrerParam,
  showLocal: showLocale,
  version,
} = BookingQueryParams;

type PayloadState = {
  consumerData: Consumer | undefined;
  jwt: string | undefined;
  layoutError: string | undefined;
  linkedAccountId: string | undefined;
  locale: string;
  loungeCode: string | undefined;
  membershipType?: string;
  payload: BridgePayload | undefined;
  platform: string | undefined;
  referrerUrl: string | undefined;
  setConsumerData: (consumer: Consumer) => void;
  setLayoutError: (err: string) => void;
  setLinkedAccountId: (linkedAccountId: string) => void;
  setPayload: (payload: BridgePayload) => void;
  setTokenError: (err: string) => void;
  tracking?: ReactSDKClient;
};

type PayloadProps = {
  apolloErrorObservable?: IObservable<ErrorResponse>;
};

const PayloadContext = createContext<null | PayloadState>(null);

const optimizely = createInstance({
  sdkKey: process.env.NEXT_PUBLIC_SDK_OPTIMEZELY,
});

export const usePayload = (): PayloadState => {
  const context = useContext(PayloadContext);

  if (!context) {
    throw new Error('Please use PayloadProvider in parent component');
  }

  return context;
};

/**
 * Basic field validation for payload
 * @param payload
 * @returns
 */
const validatePayload = (payload: BridgePayload) =>
  hasRequired(payload, ['accountProvider', 'externalId']);

export const PayloadProvider = (props: PropsWithChildren<PayloadProps>) => {
  const router = useRouter();
  const session = useSessionContext();
  const { apolloErrorObservable } = props;

  const [payload, setPayload] = useState<BridgePayload>();
  const [loungeCode, setLoungeCode] = useState<string>();
  const [jwt, setJWT] = useState<string>();
  const [tokenError, setTokenError] = useState<string>();
  const [payloadError, setPayloadError] = useState<boolean>(false);
  const [payloadErrorTitle, setPayloadErrorTitle] = useState<string>();
  const [linkedAccountId, setLinkedAccountId] = useState<string>();
  const [referrerUrl, setReferrerUrl] = useState<string>();
  const [platform, setPlatform] = useState<string>();
  const [layoutError, setLayoutError] = useState<string>();
  const [consumerData, setConsumerData] = useState<Consumer>();
  const [language, setLanguage] = useState<string>('en');

  const [
    fetchConsumer,
    { error: fetchConsumerError, loading: fetchConsumerLoading },
  ] = useLazyQuery(getConsumerByID);

  const [updateConsumerCall] = useMutation(updateConsumer);

  const translations = useLocale();

  useEffect(() => {
    if (router.isReady) {
      const queryJWT = router.query[jwtParam] as string;
      const queryLoungeCode = router.query[lcParam] as string;
      const queryReferrer = router.query[referrerParam] as string;
      const queryPlatform = router.query[platformParam] as string;
      const queryLanguage = router.query[ln] as string;
      const queryVersion = router.query[version] as string;
      const queryLocalSwitch = router.query[showLocale] as string;

      const storageJWT = getItem(JWT);
      const storageLoungeCode = getItem(LOUNGE_CODE);

      const hasStoredData = storageJWT && storageLoungeCode;
      const hasQueryParams = queryJWT && queryLoungeCode;

      let jwt = '';
      let loungeCode = '';
      let language = 'en';
      let versionPDF: string = process.env.NEXT_PUBLIC_VERSION ?? '';
      let referrer = '';
      let platform = 'web';
      let localSwitch = 'OFF';

      if (hasQueryParams) {
        log(`Param found: ${jwtParam}:${queryJWT}`);
        log(`Param found: ${lcParam}:${queryLoungeCode}`);
        jwt = queryJWT;
        loungeCode = queryLoungeCode;
        referrer = queryReferrer || '';
        platform = queryPlatform || 'web';
        language = queryLanguage || 'en';
        versionPDF = queryVersion || process.env.NEXT_PUBLIC_VERSION || '';
        localSwitch = queryLocalSwitch || 'OFF';
      } else if (hasStoredData) {
        jwt = getItem(JWT) || queryJWT;
        loungeCode = getItem(LOUNGE_CODE) || queryLoungeCode;
        referrer = getItem(REFERRER) || '';
        platform = getItem(PLATFORM) || 'web';
        language = getItem(LANGUAGE) || 'en';
        localSwitch = getItem(ALLOW_LOCAL) || 'OFF';
        versionPDF = getItem(VERSION) || process.env.NEXT_PUBLIC_VERSION || '';
        log(`Retrieved ${jwtParam} and ${lcParam} from storage`);
        log('referrer:', referrer);
        log('platform:', platform);
      }

      setPlatform(queryPlatform);

      if (!loungeCode || !jwt) {
        log(
          `Unable to retrieve ${jwtParam} or ${lcParam} from both query and storage`
        );
        logDataError(BookingError.UNABLE_TO_RETRIEVE_JWT_OR_LOUNGE);

        setTokenError('Sorry, service is not available');
        // setError({isError: true, error: null,  message: `Unable to retrieve ${jwtParam} or ${lcParam} from both query and storage`})
        return;
      }

      setItem(LOUNGE_CODE, loungeCode);
      setItem(LANGUAGE, language);
      setItem(JWT, jwt);
      setItem(REFERRER, referrer);
      setItem(PLATFORM, platform);
      setLoungeCode(loungeCode);
      setJWT(jwt);
      setReferrerUrl(referrer);
      setPlatform(platform);
      setItem(VERSION, versionPDF);
      setItem(ALLOW_LOCAL, localSwitch);
      setLanguage(language);

      let payload: BridgePayload;

      try {
        payload = decodeJWT(jwt) as BridgePayload;
      } catch (e) {
        log('Decode JWT error: ', e);

        logDataError(BookingError.UNABLE_TO_RETRIEVE_JWT_OR_LOUNGE);

        // setPayloadErrorTitle('Sorry, service is not available');
        setPayloadErrorTitle(translations.generic.error.tokenNoFound);
        setPayloadError(true);

        return;
      }

      if (!validatePayload(payload)) {
        log('JWT did not pass validatePayload() checks');
        setPayloadErrorTitle('Sorry, service is not available');
        setPayloadError(true);

        logDataError(BookingError.PAYLOAD_VALIDATION);
      }

      setPayload(payload);
    }
  }, [router]);

  const findLinkedAccount = useCallback(
    (linkedAccounts: LinkedAccount[] = []) =>
      linkedAccounts.find(accountIsEqual(payload)),
    [payload]
  );

  useEffect(() => {
    if (
      router.isReady &&
      !session.loading &&
      (payloadError || tokenError !== undefined)
    ) {
      setPayloadErrorTitle('Sorry, service is not available');
    }
  }, [tokenError, router, session]);

  // user already logged-in
  useEffect(() => {
    if (
      payload &&
      !session.loading &&
      !linkedAccountId &&
      !router.pathname.includes('/auth')
    ) {
      const { userId } = session;

      if (userId) {
        log('[payload hook] fetchConsumer ID: ', userId);

        fetchConsumer({
          variables: {
            getConsumerById: userId,
          },
        }).then(({ data }) => {
          log(
            '[payload hook] fetchConsumer response: ',
            JSON.stringify(data || null)
          );

          const consumer = data?.getConsumerByID;

          if (language !== consumer?.locale) {
            updateConsumerCall({
              variables: {
                consumerInput: {
                  emailAddress: consumer?.emailAddress,
                  locale: language,
                },
              },
            });
          }

          if (consumerIsValid(consumer)) {
            setConsumerData(data);

            const accountMatched = findLinkedAccount(
              data.getConsumerByID.linkedAccounts
            );

            if (accountMatched) {
              setLinkedAccountId(accountMatched?.id);
            } else {
              signOut();
            }
          } else {
            log('[payload hook] consumer is not valid');

            signOut();
          }
        });
      }
    }
  }, [payload, linkedAccountId, router, session]);

  return (
    <>
      <Head>
        <link
          href={`${
            payload?.accountProvider === LK
              ? '/lk-favicon.ico'
              : '/pp-favicon.ico'
          }`}
          rel="icon"
          sizes="any"
        />
      </Head>
      <PayloadContext.Provider
        value={{
          consumerData,
          jwt,
          layoutError,
          linkedAccountId,
          locale: language,
          loungeCode,
          membershipType: payload?.membershipType,
          payload,
          platform,
          referrerUrl,
          setConsumerData,
          setLayoutError,
          setLinkedAccountId,
          setPayload,
          setTokenError,
          tracking: optimizely,
        }}
      >
        <MantineProvider
          cssVariablesResolver={resolver}
          theme={mantineTheme(payload)}
        >
          <LoungeError error={fetchConsumerError} />

          {!session.loading &&
            (fetchConsumerLoading ? (
              <Flex align="center" h="100%" justify="center">
                <LoaderLifestyleX />
              </Flex>
            ) : (
              <OptimizelyProvider
                optimizely={optimizely}
                user={{ id: session.userId }}
              >
                <ErrorHook
                  apolloErrorObservable={apolloErrorObservable}
                  payload={payload}
                  payloadErrorTitle={payloadErrorTitle ?? ''}
                  platform={platform ?? ''}
                  tokenError={tokenError ?? ''}
                >
                  <OutletHook
                    apiAccountProviderMap={apiAccountProviderMap}
                    loungeCode={loungeCode ?? ''}
                    payload={payload}
                    payloadError={payloadError}
                    payloadErrorTitle={payloadErrorTitle ?? ''}
                    platform={platform ?? ''}
                    tokenError={tokenError ?? ''}
                    userIsSignedIn={Boolean(jwt && session.userId)}
                  >
                    {props.children}
                  </OutletHook>
                </ErrorHook>
              </OptimizelyProvider>
            ))}
        </MantineProvider>
      </PayloadContext.Provider>
    </>
  );
};

export default usePayload;
