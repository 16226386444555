"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/supertokens.ts
var supertokens_exports = {};
__export(supertokens_exports, {
  Session: () => import_session.default,
  SuperTokensWrapper: () => import_supertokens_auth_react.SuperTokensWrapper,
  ThirdPartyPasswordless: () => import_thirdpartypasswordless.default,
  consumePasswordlessCode: () => import_thirdpartypasswordless.consumePasswordlessCode,
  createPasswordlessCode: () => import_thirdpartypasswordless.createPasswordlessCode,
  default: () => import_supertokens_auth_react.default,
  doesSessionExist: () => import_session.doesSessionExist,
  getAccessToken: () => import_session.getAccessToken,
  getUserId: () => import_session.getUserId,
  redirectToAuth: () => import_supertokens_auth_react.redirectToAuth,
  signOut: () => import_thirdpartypasswordless.signOut,
  useSessionContext: () => import_session.useSessionContext
});
module.exports = __toCommonJS(supertokens_exports);
var import_session = __toESM(require("supertokens-auth-react/recipe/session"));
var import_thirdpartypasswordless = __toESM(require("supertokens-auth-react/recipe/thirdpartypasswordless"));
var import_supertokens_auth_react = __toESM(require("supertokens-auth-react"));
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  Session,
  SuperTokensWrapper,
  ThirdPartyPasswordless,
  consumePasswordlessCode,
  createPasswordlessCode,
  doesSessionExist,
  getAccessToken,
  getUserId,
  redirectToAuth,
  signOut,
  useSessionContext
});
