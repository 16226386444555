export var AccountProvider;
(function(AccountProvider) {
    AccountProvider["PP"] = "PRIORITY_PASS";
    AccountProvider["LK"] = "LOUNGE_KEY";
})(AccountProvider || (AccountProvider = {}));
export function getAccountProviders() {
    return [
        "PRIORITY_PASS",
        "LOUNGE_KEY"
    ];
}
export default AccountProvider;
