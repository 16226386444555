var BookingQueryParams;
(function(BookingQueryParams) {
    BookingQueryParams["loungeCode"] = "loungeCode";
    BookingQueryParams["ln"] = "ln";
    BookingQueryParams["jwt"] = "linkAccountToken";
    BookingQueryParams["referrer"] = "referrer";
    BookingQueryParams["platform"] = "platform";
    BookingQueryParams["bookingId"] = "bookingId";
    BookingQueryParams["version"] = "version";
    BookingQueryParams["showLocal"] = "showlocale";
})(BookingQueryParams || (BookingQueryParams = {}));
export default BookingQueryParams;
