import { gql } from '@collinsonx/utils/apollo';

const getBookingOutlet = gql`
  query GetOutlet(
    $getOutletId: ID!
    $outletIdType: outletIDType!
    $additionalFilters: ProductFilters
  ) {
    getOutlet(
      outletIDType: $outletIdType
      additionalFilters: $additionalFilters
      id: $getOutletId
    ) {
      id
      name
      code
      legacyCode
      category
      partnerBrand {
        id
        partnerIntegrationData {
          partnerIntegrationID
        }
      }
      location {
        timezone
        code
        name
        terminal
      }
      products {
        id
        name
        partnerIntegrationData {
          partnerProductID
        }
        salePrices {
          programme
          salePrice
          salePriceCurrency
        }
        status
        accessType
      }
      media {
        mainImage {
          asset {
            url
          }
        }
      }
    }
  }
`;
export default getBookingOutlet;
