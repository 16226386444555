"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/lib/userContext.tsx
var userContext_exports = {};
__export(userContext_exports, {
  UserProvider: () => UserProvider,
  useUserId: () => useUserId
});
module.exports = __toCommonJS(userContext_exports);
var import_react = require("react");
var import_jsx_runtime = require("react/jsx-runtime");
var userContext = (0, import_react.createContext)({ user: { userId: null } });
var UserProvider = ({ children }) => {
  const [user, setUser] = (0, import_react.useState)({ userId: null });
  return /* @__PURE__ */ (0, import_jsx_runtime.jsx)(userContext.Provider, { value: { user, setUser }, children });
};
var useUserId = () => {
  const { user, setUser } = (0, import_react.useContext)(userContext);
  return [user, setUser];
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  UserProvider,
  useUserId
});
