"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/lib/analytics.ts
var analytics_exports = {};
__export(analytics_exports, {
  loggerAction: () => loggerAction,
  loggerDataError: () => loggerDataError,
  loggerInfo: () => loggerInfo
});
module.exports = __toCommonJS(analytics_exports);
var loggerDataError = (error, file, action, data, shouldLog = false, datadogLogs) => {
  if (shouldLog) {
    if (typeof document !== "undefined") {
      datadogLogs.logger.error(
        "Frontend Error Occured",
        {
          file,
          action,
          data
        },
        error
      );
    }
  }
};
var loggerInfo = (file, action, data, shouldLog = false, datadogLogs) => {
  if (shouldLog) {
    if (typeof document !== "undefined") {
      datadogLogs.logger?.info("Frontend Info", {
        file,
        action,
        data
      });
    }
  }
};
var loggerAction = async (file, action, data, datadogRum) => {
  if (typeof document !== "undefined") {
    await datadogRum.addAction(action, {
      file,
      data
    });
  }
};
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  loggerAction,
  loggerDataError,
  loggerInfo
});
