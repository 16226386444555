"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/apollo.ts
var apollo_exports = {};
__export(apollo_exports, {
  APOLLO_STATE_PROP_NAME: () => APOLLO_STATE_PROP_NAME,
  ApolloError: () => import_client2.ApolloError,
  ApolloProvider: () => import_client2.ApolloProvider,
  addApolloState: () => addApolloState,
  gql: () => import_client2.gql,
  initializeApollo: () => initializeApollo,
  useApollo: () => useApollo,
  useLazyQuery: () => import_client2.useLazyQuery,
  useMutation: () => import_client2.useMutation,
  useQuery: () => import_client2.useQuery
});
module.exports = __toCommonJS(apollo_exports);
var import_client = require("@apollo/client");
var import_link_error = require("@apollo/link-error");
var import_deepmerge = __toESM(require("deepmerge"));
var import_isEqual = __toESM(require("lodash/isEqual"));
var import_react = require("react");
var import_session = require("supertokens-auth-react/recipe/session");
var import_client2 = require("@apollo/client");
var graphqlUrl = process.env.NEXT_PUBLIC_PRODUCTION_API_URL;
var defaultOptions = {
  watchQuery: {
    fetchPolicy: "network-only",
    errorPolicy: "all"
  },
  query: {
    fetchPolicy: "network-only",
    errorPolicy: "all"
  },
  mutate: {
    fetchPolicy: "network-only",
    errorPolicy: "all"
  }
};
var checkProtected = () => {
  if (typeof window !== "undefined") {
    const { pathname } = window.location;
    return !pathname.startsWith("/auth") && !pathname.startsWith("/signup");
  }
};
var redirectLogin = () => {
  if (typeof window !== "undefined") {
    window.location.href = `/auth/login/?redirecturl=${window.location.pathname + window.location.search}`;
  }
};
var withTokenRefresh = new import_client.ApolloLink((operation, forward) => {
  if (checkProtected()) {
    return (0, import_client.fromPromise)(
      (0, import_session.attemptRefreshingSession)().then((success) => {
        if (!success) {
          redirectLogin();
        }
        return (0, import_client.toPromise)(forward(operation));
      })
    );
  }
  return forward(operation);
});
var httpLink = new import_client.HttpLink({
  uri: graphqlUrl,
  credentials: "include"
});
var errorLink = (0, import_link_error.onError)(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(
      ({ message, locations, path }) => console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }
  if (networkError) {
    console.log(
      `[Network error]: ${networkError}. Backend is unreachable. Is it running?`,
      `[Graphql URL]: ${graphqlUrl}`
    );
  }
});
var APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";
var apolloClient;
function createApolloClient() {
  return new import_client.ApolloClient({
    ssrMode: typeof window === "undefined",
    link: import_client.ApolloLink.from([errorLink, withTokenRefresh, httpLink]),
    cache: new import_client.InMemoryCache(),
    defaultOptions
  });
}
function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();
  if (initialState) {
    const existingCache = _apolloClient.extract();
    const data = (0, import_deepmerge.default)(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(
          (d) => sourceArray.every((s) => !(0, import_isEqual.default)(d, s))
        )
      ]
    });
    _apolloClient.cache.restore(data);
  }
  if (typeof window === "undefined")
    return _apolloClient;
  if (!apolloClient)
    apolloClient = _apolloClient;
  return _apolloClient;
}
function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }
  return pageProps;
}
function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = (0, import_react.useMemo)(() => initializeApollo(state), [state]);
  return store;
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  APOLLO_STATE_PROP_NAME,
  ApolloError,
  ApolloProvider,
  addApolloState,
  gql,
  initializeApollo,
  useApollo,
  useLazyQuery,
  useMutation,
  useQuery
});
