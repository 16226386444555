import en from 'locales/en';

const defaultLangObj = en;

const useLocale = () =>
  // Returning English as default language for June/24 release
  // Just uncomment the code when it's time to enable translations

  // const allowLocale = getItem(ALLOW_LOCAL);
  // const locale = getItem(LANGUAGE) ?? 'en';

  // if (process.env.NODE_ENV === 'test') return defaultLangObj;
  // if (allowLocale === SWITCHES.LOCAL_SWITCH_OFF) return defaultLangObj;
  // // TODO load language dynamically from the server
  // if (locale in languages) return languages[locale];
  defaultLangObj;
export default useLocale;
