export var Client;
(function(Client) {
    Client["Mastercard"] = "MASTERCARD";
    Client["None"] = "NONE";
    Client["Mastercard_HSBC"] = "MASTERCARD_HSBC";
    Client["AMEX_UK"] = "AMEX_UK";
    Client["Mastercard_MCAE"] = "MASTERCARD_MCAE";
})(Client || (Client = {}));
export function getClients() {
    return [
        "MASTERCARD",
        "NONE",
        "MASTERCARD_HSBC",
        "AMEX_UK",
        "MASTERCARD_MCAE"
    ];
}
export default Client;
