"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "prefixCSSVars", {
    enumerable: true,
    get: function() {
        return prefixCSSVars;
    }
});
var prefixCSSVars = function(obj) {
    return Object.keys(obj).reduce(function(prev, cur) {
        return prev["--" + cur] = obj[cur], prev;
    }, {});
};
