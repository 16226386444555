export const OAG_API_VERSION = 'v2';
export const TRAVEL_TYPE = 'DEPARTURE';
export const LOUNGE = 'Lounge';
export const CODE_IATA = 'IATA';
export const CODE_ICAO = 'ICAO';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';
export const DATE_READABLE_FORMAT = 'ddd D MMMM YYYY';
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const FAQ_PP = 'https://memberhelp.prioritypass.com/en/support/home';
export const FAQ_LK = 'https://www.loungekey.com/en/contact-us';
export const FAQ_AMEX_PREBOOK_ENTITLEMENTS =
  'https://prioritypass.com/pre-book-entitlements';
