import {
  AppleStoreIcon,
  GoogleStoreIcon,
} from '@collinsonx/design-system/assets/icons';
import * as React from 'react';

export type devices = 'android' | 'ios' | 'web';
export interface UpdateEnvInterface {
  type: devices;
}

const UpdateEnvError = ({ type }: UpdateEnvInterface) => (
  <div>
    {type === 'android' && (
      <a
        href="https://play.google.com/store/apps/details?id=com.prioritypass3&pli=1"
        style={{ marginTop: '20px', textDecoration: 'none' }}
      >
        <GoogleStoreIcon /> Update Priority Pass&#x2122;
      </a>
    )}

    {type === 'ios' && (
      <a
        href="https://apps.apple.com/gb/app/priority-pass/id406878019"
        style={{ marginTop: '20px', textDecoration: 'none' }}
      >
        <AppleStoreIcon /> Update Priority Pass&#x2122;
      </a>
    )}
  </div>
);

export default UpdateEnvError;
