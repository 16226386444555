import { AccountProvider, Client } from '@collinsonx/constants/enums';
import {
  Anchor,
  Box,
  Center,
  Container,
  Flex,
  Text,
} from '@collinsonx/design-system/core';
import useLocale from 'hooks/useLocale';

import AppLogo from '../AppLogo';
import BackButton from '../BackButton';
import UpdateEnvError, { devices } from '../UpdateEnvError';
import classes from './LayoutError.module.css';

interface LayoutProps {
  payloadErrorButton?: string;
  payloadErrorMessage?: string;
  payloadErrorMobileMessage?: string;
  payloadErrorTitle?: string;
  payloadPlatform: string;
  payloadTheme: {
    accountProvider: AccountProvider;
    membershipType: Client;
  };
}

export default function LayoutError(props: LayoutProps) {
  const translation = useLocale();

  const {
    payloadErrorButton = translation.auth.notFound.btn.returnToLounge,
    payloadErrorMessage = translation.lounge.errors.server.description,
    payloadErrorMobileMessage = translation.auth.notFound.descriptionMobile,
    payloadErrorTitle = translation.lounge.errors.server.title,
    payloadPlatform,
    payloadTheme,
  } = props;

  return (
    <Container className={classes.container} fluid px={0}>
      <Box className={classes.logoWrapper} mb={2} mt={2}>
        <AppLogo
          accountProvider={payloadTheme.accountProvider}
          membershipType={payloadTheme.membershipType}
        />
      </Box>
      <Center
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '70%',
        }}
      >
        <Box className={classes.containerContent} p={20}>
          <Text className={classes.center} fw={700} size="xl">
            {payloadErrorTitle}
          </Text>
          <Text className={classes.center} mb={18}>
            {payloadErrorMessage}
          </Text>
          <Box pb={10} pt={10}>
            <Flex className={classes.errorContainer} direction="column">
              {' '}
              <UpdateEnvError type={payloadPlatform as devices} />
              {(payloadPlatform === 'ios' || payloadPlatform === 'android') && (
                <Text mb={3} mt={3}>
                  {payloadErrorMobileMessage}
                </Text>
              )}
            </Flex>
          </Box>
          <Center>
            <Anchor href="#">
              <BackButton>{payloadErrorButton}</BackButton>
            </Anchor>
          </Center>
        </Box>
      </Center>
    </Container>
  );
}
