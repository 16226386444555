"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/lib/cookieHandler.ts
var cookieHandler_exports = {};
__export(cookieHandler_exports, {
  default: () => getCookieHandler,
  getCookiesFromStorage: () => getCookiesFromStorage
});
module.exports = __toCommonJS(cookieHandler_exports);
var frontendCookiesKey = "frontendCookies";
var inMemoryStorage = {};
function setKeyValue(key, value) {
  try {
    if (window) {
      window.localStorage.setItem(key, value);
    }
  } catch (err) {
    inMemoryStorage[key] = value;
  }
}
function getKeyValue(key) {
  try {
    return window ? window.localStorage.getItem(key) : null;
  } catch (err) {
    if (inMemoryStorage[key] === void 0) {
      return null;
    } else {
      return inMemoryStorage[key];
    }
  }
}
function getCookiesFromStorage() {
  const cookiesFromStorage = getKeyValue(frontendCookiesKey);
  if (cookiesFromStorage === null) {
    setKeyValue(frontendCookiesKey, "[]");
    return "";
  }
  const cookieArrayInStorage = JSON.parse(cookiesFromStorage);
  let cookieArrayToReturn = [];
  for (let cookieIndex = 0; cookieIndex < cookieArrayInStorage.length; cookieIndex++) {
    const currentCookieString = cookieArrayInStorage[cookieIndex];
    const parts = currentCookieString.split(";");
    let expirationString = "";
    for (let partIndex = 0; partIndex < parts.length; partIndex++) {
      const currentPart = parts[partIndex];
      if (currentPart.toLocaleLowerCase().includes("expires=")) {
        expirationString = currentPart;
        break;
      }
    }
    if (expirationString !== "") {
      const expirationValueString = expirationString.split("=")[1];
      const expirationDate = new Date(expirationValueString);
      const currentTimeInMillis = Date.now();
      if (expirationDate.getTime() < currentTimeInMillis) {
        continue;
      }
    }
    cookieArrayToReturn.push(currentCookieString);
  }
  setKeyValue(frontendCookiesKey, JSON.stringify(cookieArrayToReturn));
  return cookieArrayToReturn.join("; ");
}
function setCookieToStorage(cookieString) {
  const cookieName = cookieString.split(";")[0].split("=")[0];
  const cookiesFromStorage = getKeyValue(frontendCookiesKey);
  let cookiesArray = [];
  if (cookiesFromStorage !== null) {
    const cookiesArrayFromStorage = JSON.parse(cookiesFromStorage);
    cookiesArray = cookiesArrayFromStorage;
  }
  let cookieIndex = -1;
  for (let i = 0; i < cookiesArray.length; i++) {
    const currentCookie = cookiesArray[i];
    if (currentCookie.indexOf(`${cookieName}=`) !== -1) {
      cookieIndex = i;
      break;
    }
  }
  if (cookieIndex !== -1) {
    cookiesArray[cookieIndex] = cookieString;
  } else {
    cookiesArray.push(cookieString);
  }
  setKeyValue(frontendCookiesKey, JSON.stringify(cookiesArray));
}
function getCookieHandler(original) {
  return {
    ...original,
    getCookie: async function() {
      const cookies = getCookiesFromStorage();
      return cookies;
    },
    setCookie: async function(cookieString) {
      setCookieToStorage(cookieString);
    }
  };
}
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  getCookiesFromStorage
});
