import { AccountProvider } from '@collinsonx/constants/enums';
import {
  Experience,
  Maybe,
  Outlet,
  OutletIdType,
  PrimaryProductAccessType,
  Product,
} from '@collinsonx/utils';
import { useLazyQuery } from '@collinsonx/utils/apollo';
import { getSearchExperiences } from '@collinsonx/utils/queries';
import {
  convertExperienceToLounge,
  convertLoungeToProduct,
  convertOutletToLounge,
  Lounge,
} from 'datahelpers/outletConvertor';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { BridgePayload } from 'types/booking';

import { FEATURE_FLAGS } from '../constants';
import getBookingOutlet from '../gql-queries/getBookingOutlet';
import { useError } from './error';
import useFeatureFlag from './useFeatureFlag';
import useLocale from './useLocale';

interface OutletHookProps {
  apiAccountProviderMap: Record<AccountProvider, string>;
  children: React.ReactNode;
  loungeCode: string;
  payload: BridgePayload | undefined;
  payloadError: boolean;
  payloadErrorTitle: string;
  platform: string;
  tokenError: string;
  userIsSignedIn: boolean;
}

type OutletState = {
  lounge?: Maybe<Lounge>;
  productId?: string;
  products?: Maybe<Product>[];
};

const OutletContext = createContext<null | OutletState>(null);

export const useProduct = (): OutletState => {
  const context = useContext(OutletContext);

  if (!context) {
    throw new Error('Please use ProductProvider in parent component');
  }

  return context;
};

export const setOutletStorage = (outlet: OutletState) => {
  if (typeof window === 'undefined') return;

  sessionStorage.setItem('outlet', JSON.stringify(outlet));
};

export const getOutletStorage = (): OutletState => {
  if (typeof window === 'undefined') return {};

  return JSON.parse(sessionStorage.getItem('outlet') ?? '{}');
};

export const OutletHook = ({
  apiAccountProviderMap,
  children,
  loungeCode,
  payload,
  payloadError,
  payloadErrorTitle,
  tokenError,
  userIsSignedIn,
}: OutletHookProps) => {
  const outletStorage = getOutletStorage();
  const translation = useLocale();
  const [products, setProducts] = useState<Maybe<Product>[] | undefined>(
    outletStorage.products
  );
  const [lounge, setLounge] = useState<Maybe<Lounge> | undefined>(
    outletStorage.lounge
  );
  const { setError } = useError();
  const useProductCatalogueExperiment = useFeatureFlag(
    FEATURE_FLAGS.PRODUCT_CATALOGUE_EXPERIMENT,
    {
      enabledByDefault: true,
    }
  );

  const productId = products?.[0]?.id;

  const setOutlet = (outlet: OutletState) => {
    setOutletStorage(outlet);
    setProducts(outlet.products);
    setLounge(outlet.lounge);
  };

  useEffect(() => {
    if (payloadError) {
      setError({
        error: null,
        isError: payloadError,
        message: payloadErrorTitle,
      });
    } else if (tokenError) {
      setError({ error: null, isError: true, message: tokenError });
    }
  }, [tokenError, payloadError, payloadErrorTitle]);

  const [fetchExperiences] = useLazyQuery<{
    searchExperiences: Experience[];
  }>(getSearchExperiences, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const experience = data?.searchExperiences.filter(
        (item) => item && item.loungeCode === loungeCode
      )[0];

      if (
        typeof experience === 'undefined' ||
        data?.searchExperiences.length === 0
      )
        return setError({
          error: null,
          isError: true,
          message: translation.generic.error.loungeNoFound,
        });

      const product = convertLoungeToProduct(experience);
      const lounge = convertExperienceToLounge(experience);

      setOutlet({
        lounge,
        products: [product],
      });
    },
    onError: (error) => {
      setError({
        error,
        isError: true,
        message: translation.generic.error.loungeNoFound,
      });
    },
  });

  const [fetchOutlet] = useLazyQuery<{
    getOutlet: Outlet;
  }>(getBookingOutlet, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const product = data?.getOutlet?.products.filter(
        (product) =>
          product?.accessType === PrimaryProductAccessType.ReservationFeeOnly
      );

      const lounge = product
        ? convertOutletToLounge(data.getOutlet, product[0])
        : false;

      if (!lounge)
        return setError({
          error: null,
          isError: true,
          message: translation.generic.error.loungeNoFound,
        });

      setOutlet({
        lounge,
        products: data.getOutlet.products.filter(
          (product) =>
            product?.accessType === PrimaryProductAccessType.ReservationFeeOnly
        ),
      });
    },
    onError: (error) => {
      setError({
        error,
        isError: true,
        message: translation.generic.error.loungeNoFound,
      });
    },
  });

  useEffect(() => {
    if (!apiAccountProviderMap[payload?.accountProvider!]) return;
    if (!userIsSignedIn) return;
    if (lounge && products) return;

    if (useProductCatalogueExperiment) {
      fetchOutlet({
        variables: {
          accessType: 'RESERVATION_FEE_ONLY',
          additionalFilters: {
            accessType: PrimaryProductAccessType.ReservationFeeOnly,
            programme: payload?.accountProvider,
          },
          getOutletId: loungeCode,

          outletIdType: OutletIdType.LegacyCode,
          programme: 'PRIORITY_PASS',
        },
      });
    } else {
      fetchExperiences({
        variables: {
          searchFilter: {
            attribute: apiAccountProviderMap[payload?.accountProvider!],
            value: 'true',
          },
        },
      });
    }
  }, [payload, userIsSignedIn]);

  const contextValue = useMemo(
    () => ({
      lounge,
      productId,
      products,
    }),
    [products, lounge]
  );

  return (
    <>
      <OutletContext.Provider value={contextValue}>
        {payload && children}
      </OutletContext.Provider>
    </>
  );
};

export default useProduct;
