"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
var colors = {
    white: "#FFF",
    "text-default": "#202020",
    "text-grey": "#6B6B6B",
    "bg-surface": "#FBFBFB",
    "bg-surface-mid": "#F7F7F7",
    "bg-surface-dark": "#EFEFF1",
    "bg-th": "#EFEFF1",
    "grey-border": "#D5D5D5",
    "brand-cergea": "#6D4BF6",
    "bg-cergea": "#F0EDFE",
    "bg-cergea-darker": "#E2DBFD",
    "bg-active-pagination-button": "#CE0058",
    "brand-collinson": "#CE0058",
    "bg-collinson": "#FAE5EE",
    "bg-collinson-darker": "#F5CCDE",
    "bg-green": "#E9FAC8",
    "bg-blue": "#D3E4F5",
    "accent-blue": "#376089",
    "bg-blue-darker": "#A8C7E6",
    "bg-red": "#FFE3E3",
    "bg-amber": "#FFF3BF",
    "bg-amber-darker": "#FBEA9E",
    "accent-green": "#487A04",
    "accent-amber": "#965101",
    "accent-red": "#C21C1C",
    "bg-light-blue": "#D3E4F5",
    "border-collinson": "#F3C4D2",
    "input-focus": "#CE0058",
    "secondary-focus": "#545b62",
    "grey-mid": "#CECECE"
};
var _default = colors;
