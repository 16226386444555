import { Notification, Text } from '@collinsonx/design-system/core';
import { ApolloError } from '@collinsonx/utils/apollo';

export interface LoungeErrorProps {
  error?: ApolloError;
  title?: string;
}
const LoungeError = ({
  error,
  title = 'An error occurred',
}: LoungeErrorProps) => (
  <>
    {error ? (
      <Notification color="red.7" my={8} title={title} w="100%">
        {error.graphQLErrors.map((err, index) => (
          <Text key={index}>{err.message}</Text>
        ))}
      </Notification>
    ) : null}
  </>
);

export default LoungeError;
